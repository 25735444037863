import React from "react"
import WpAcfBigtextBlock from "@components/modules/WpAcfBigtextBlock"
import img404 from "@images/404.jpeg"

const ErrorPage = () => {
  const data = {
    moduleBigtext: {
      headline: "Sorry - haben wir nicht mehr.",
      text: "Zur Home",
      topImage: img404,
    },
  }
  return (
    <div className="bg-black wrap">
      <WpAcfBigtextBlock data={data} errorPage={true} />
    </div>
  )
}

export default ErrorPage
