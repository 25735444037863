import React from "react"
import tw, { styled } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { useStateContext } from "@context/stateContext"
import { Link } from "gatsby"
import getTransition from "./../../utils/getTransition"

const Section = tw.section`relative pb-spacing-75 overflow-hidden pt-spacing-master md:pt-spacing-75 lg:pt-spacing-100 2xl:pt-spacing-150 md:pb-spacing-100 2xl:pb-spacing-150`
const SectionWrapper = tw.div`w-full relative px-spacing-15 md:px-offset-xs 3xl:px-offset-master 4xl:px-spacing-75`
const ImageWrapper = tw.div`flex justify-center relative w-full mt-offset-master`
const ContentWrapper = tw.div`w-full`
const Overline = tw.p`text-white text-20px/140 md:text-22px/140 lg:text-28px/140 3xl:text-32px/140`
const Headline = tw.p`font-primary text-white mr-auto text-clampHeadlineBig pb-spacing-xs md:pb-spacing-master 2xl:pb-spacing-100 w-full md:w-4/5 lg:w-3/4 4xl:w-2/3 relative z-10`
const Text = styled.div`
  ${tw`relative z-20 text-white text-20px/140 md:text-22px/140 lg:text-28px/140 2xl:text-32px/140 sm:max-w-[500px] lg:max-w-[650px] 2xl:w-full 2xl:max-w-[650px] 4xl:max-w-[900px]`}

  .bigger {
    ${tw`text-22px/140 md:text-28px/140 lg:text-32px/140 2xl:text-50px/140 `}
  }
  .smaller {
    ${tw`text-16px/150 2xl:text-20px/140`}
  }
  a {
    ${tw`underline`}
  }
  p:not(:last-child) {
    ${tw`pb-spacing-xs 2xl:pb-spacing-master`}
  }
`

const SecondImageWrapper = tw.div`relative`

type WpAcfBigtextBlockProps = {
  children?: React.ReactNode
  data: {
    moduleBigtext: any
    attributesJSON: string
    attributes: any
  }
  errorPage?: Boolean
}

const WpAcfBigtextBlock = ({ data, errorPage }: WpAcfBigtextBlockProps) => {
  const module = data.moduleBigtext
  const attributes = data.attributes
  const classNames = attributes?.className.split(" ")
  const sectionRef = React.useRef()
  const contentRef = React.useRef()
  const tl = React.useRef()
  const q = gsap.utils.selector(sectionRef)
  gsap.registerPlugin(ScrollTrigger)
  const { setMouseInteracting, language, setTsA } = useStateContext()

  const gsapInit = () => {
    tl.current && tl.current.progress(0).kill()
    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top bottom",
        end: "bottom top",
        scrub: 2,
      },
    })
    tl.current.add("zero", 0)
    module.topImage?.localFile && tl.current.to(q(".big-text-image"), { duration: 2, y: "-=50px", ease: "power1.easeInOut" }, "zero")
    tl.current.to(q(".big-text-deco"), { duration: 2, y: "-=180px", ease: "power1.easeInOut" }, "zero")
    module.overline && tl.current.to(q(".big-text-overline"), { duration: 2, y: "+=20px", ease: "power1.easeInOut" }, "zero")
    tl.current.to(q(".big-text-headline"), { duration: 2, y: "+=40px", ease: "power1.easeInOut" }, "zero")
    module.text && tl.current.to(q(".big-text-desc"), { duration: 2, y: "+=40px", ease: "power1.easeInOut" }, "zero")
  }

  const navItemClick = (e, to, transitionState) => {
    e.preventDefault()
    setTsA(transitionState)
    getTransition(to, transitionState)
  }

  React.useLayoutEffect(() => {
    if (sectionRef.current) gsapInit()
  }, [])
  React.useEffect(() => {
    if (contentRef.current) {
      const allContentLinks = contentRef.current.querySelectorAll("a")
      if (allContentLinks.length > 0) {
        allContentLinks.forEach(link => link.addEventListener("mouseenter", () => setMouseInteracting(true)))
        allContentLinks.forEach(link => link.addEventListener("mouseleave", () => setMouseInteracting(false)))
      }
    }
  }, [])

  return (
    <Section ref={sectionRef} className={classNames?.includes("mob-first") ? "order-first sm:-order-none" : ""}>
      <SectionWrapper>
        <ImageWrapper>
          {module.topImage?.localFile && <GatsbyImage objectFit="contain" className="big-text-image !absolute w-3/5 lg:w-1/2 mx-offset-xs" image={getImage(module.topImage.localFile)} alt="" />}
          {errorPage && <img className="big-text-image !absolute w-3/5 lg:w-1/2 mx-offset-xs object-contain" src={module.topImage} alt="" />}
          <svg
            className={`${module.topImage?.localFile || errorPage ? "max-w-full h-full" : "h-[400%] md:h-[300%]"} absolute top-0 big-text-deco 2xl:h-auto 2xl:max-w-none`}
            width="2047"
            height="2163"
            viewBox="0 0 2047 2163"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2045 701.78C2045 701.78 1946.7 373.408 1649.79 571.583C1352.87 769.761 1377.08 995.84 1533.7 947.274C1690.32 898.71 1405.8 652.746 1230.15 871.302C1054.51 1089.86 1260.57 1721.05 714.157 1790.55C167.741 1860.05 67.1173 1424.55 2 1268.13"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M108 2112.52C108 2112.52 428.681 2303.84 521.277 1926.37C613.867 1548.91 427.998 1385.21 337.953 1539.39C247.908 1693.57 657.494 1681.77 637.681 1377.13C617.864 1072.5 -12.2822 717.338 375.429 259.679C763.141 -197.984 1164.55 77.6208 1332 155.306"
              stroke="white"
              strokeWidth="2"
            />
          </svg>
          <ContentWrapper className={module.topImage?.localFile || errorPage ? "relative mt-[250px] md:mt-[375px] lg:mt-[350px] 2xl:mt-[450px]" : "pt-spacing-master lg:pt-spacing-150"}>
            {module.overline && <Overline className="big-text-overline">{parse(module.overline)}</Overline>}
            {!errorPage && module.headline && <Headline className="big-text-headline">{parse(module.headline)}</Headline>}
            {errorPage && <Headline className="big-text-headline">{language === "de" ? "Sorry - haben wir nicht mehr." : "Sorry - we do not have anything here."}</Headline>}
            {module.text && (
              <Text ref={contentRef} className="big-text-desc">
                {!errorPage && parse(module.text)}
                {errorPage && <Link to={language === "de" ? "/" : "/en/"}>{language === "de" ? "Zur Home" : "Back to home"}</Link>}

                {module.imageDecoration?.localFile && module.decorationLink && (
                  <Link to={module.decorationLink?.url} onClick={e => navItemClick(e, module.decorationLink.url, "clip-circle")}>
                    <GatsbyImage
                      objectFit="contain"
                      className="z-0 big-text-image-decoration !absolute right-0 w-24 -translate-y-full sm:translate-y-0 sm:-right-10 sm:w-32 lg:-right-20 top-0 sm:translate-x-full md:w-48 w- lg:w-60 xl:max-w-screen-xs xl:w-full"
                      image={getImage(module.imageDecoration.localFile)}
                      alt=""
                    />
                  </Link>
                )}
              </Text>
            )}
          </ContentWrapper>
        </ImageWrapper>
      </SectionWrapper>
      {module.secondImage?.localFile && (
        <SecondImageWrapper>
          <GatsbyImage image={getImage(module.secondImage.localFile)} alt="" />
          <svg className="absolute top-0 right-0 h-full transform translate-x-1/2 -translate-y-1/2" width="560" height="1540" viewBox="0 0 560 1540" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 127.788C2 127.788 180.285 192.479 200.096 1C252.095 190.803 403.143 184.716 833.999 189.89C470.092 200.241 209.999 161.428 212.475 262.341C212.475 341.427 358.571 319.267 358.571 319.267C358.571 319.267 195.142 334.792 229.81 386.542C264.476 438.294 769.618 367.137 873.619 279.159C770.857 352.906 577.713 484.87 1042 412.417C950.381 433.118 453.904 487.457 747.333 565.082C621.048 596.133 531.905 865.238 863.713 873.001C576.476 911.814 348.666 891.113 348.666 1209.38C348.666 1527.65 921.162 1403.45 1042 1179.37C987.523 1303.57 871.638 1430.88 1042 1538C861.733 1443.3 833.999 1416.39 725.047 1416.39C546.762 1416.39 655.714 1538 655.714 1538C655.714 1538 484.857 1325.82 2 1538C197.62 1398.27 531.905 963.565 180.285 963.565C306.572 940.277 520.572 910.274 507.142 847.126C494.762 788.906 211.238 834.189 211.238 834.189C211.238 834.189 591.333 787.611 593.809 720.336C596.285 653.061 440.285 730.686 440.285 730.686C440.285 730.686 601.382 668.586 561.619 572.845C521.854 477.108 403.143 653.061 403.143 653.061C403.143 653.061 487.333 493.495 338.762 518.508C216.528 539.087 113.428 1209.38 113.428 1209.38C113.428 1209.38 196.133 513.334 29.2385 518.508C123.334 513.334 197.62 492.633 170.381 430.531C152.872 390.612 2 391.718 2 391.718C2 391.718 195.142 389.129 153.048 298.566C110.952 208.004 2 306.328 2 306.328C2 306.328 138.191 202.828 2 127.788Z"
              stroke="white"
              strokeWidth="2"
            />
          </svg>

          <svg className="absolute bottom-0 left-0 h-full max-w-full" width="1387" height="946" viewBox="0 0 1387 946" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-100 9.49871C-100 9.49871 800.226 -73.0081 1232.19 318.882C1664.16 710.773 1093.78 677.49 594.243 595.15C-170.041 469.18 -134.7 851.434 536.249 1145" stroke="white" strokeWidth="2" />
          </svg>
        </SecondImageWrapper>
      )}
    </Section>
  )
}

export default WpAcfBigtextBlock
